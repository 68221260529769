import { useEffect } from 'react';
import { createScriptTags } from './../utils/helpers';

const useAddScriptTags = scriptOptions => {
    
    useEffect(() => {
        createScriptTags(scriptOptions);
    }, [scriptOptions]);

    return null;
}

export default useAddScriptTags;