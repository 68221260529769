/**
 * Create script tags and add them to the document
 * 
 * @param {array} tagOptions 
 * tag options is an array of objects that contain the following properties
 *  id: string - the id of the script tag
 *  src: string - the src of the script tag [optional - defaults to null]
 *  textContent: string - the text content of the script tag [optional - null]
 *  async: boolean - whether the script tag should be async [optional - defaults to false]
 *  defer: boolean - whether the script tag should be deferred [optional - defaults to false]
 *  insertInHead: boolean - whether the script tag should be inserted in the head of the document [optional - defaults to false]
 * 
 * @returns {void}
 */
export const createScriptTags = (tagOptions = []) => {
    /**
     * Check for the DOM to be ready
     * once ready add the script tags
    */
    const checkForDom = setInterval(() => {
        if (document.body) {
            clearInterval(checkForDom)  
            tagOptions.forEach((options) => {
                const { id } = options
                const existingScript = document.getElementById(id)
                if (!existingScript) {
                    addScriptTag({ ...options })
                    .then(() => {
                        console.log('Script tag added successfully')
                    })
                    .catch(err => {
                        errorLogger('Error adding script tag', err)
                    })
                }
            })
        }
    }, 100);

    /**
     * Log errors 
     * 
     * ?For now this only logs to the console but could be expanded to
     * ?other logging services
     * 
     * @param {string} msg a warning message to display
     * @param {object} err the error event 
     */
    const errorLogger = (msg, err) => {
        console.warn(msg)
        console.error(err)
    }
    /**
     * Adds a script tag to the document
     * 
     * @param {*} param0
     * @param {*} param0.id
     * @param {*} param0.src
     * @param {*} param0.textContent
     * @param {*} param0.async
     * @param {*} param0.defer
     * @param {*} param0.insertInHead
     * 
     * @returns 
     */
    const addScriptTag = ({
        id, 
        src = null, 
        textContent = null, 
        async = false, 
        defer = false,
        insertInHead = false
    }) => {
        return new Promise((resolve, reject) => {
            if (!src && !textContent) {
                new Error('No src or textContent provided for script tag')
                reject()
            }
            if (src && textContent) {
                new Error('Both src and textContent provided for script tag, it should be one or the other')
                reject()
            }
            const s = document.createElement('script')
            const loadHandler = () => {
                s.removeEventListener('load', loadHandler)
                resolve()
            }
            const errorHandler = () => {
                s.removeEventListener('error', errorHandler)
                reject()
            }
            // apply options to the script tag
            if (src !== null && typeof src === 'string') s.setAttribute('src', src);
            s.textContent = (textContent !== null && typeof textContent === 'string') ? textContent : null;
            if (id !== null && typeof id === 'string') s.setAttribute('id', id);
            if (async) s.setAttribute('async', async); 
            if (defer) s.setAttribute('defer', defer);
            // setup event listeners to track outcome of adding the script tag           
            s.addEventListener('load', loadHandler);
            s.addEventListener('error', errorHandler);
    
            (insertInHead) ? document.head.appendChild(s) : document.body.appendChild(s);
        });
    };
}