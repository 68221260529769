import { useEffect } from 'react';

const useSourcePointPrivacyTrigger = ({ messageId = 1192723 }) => {
    useEffect(() => {
        let tries = 0;
        // wait for the privacy preference button to render then add an event listener
        // as per instructions from Sourcepoint (https://docs.sourcepoint.com/hc/en-us/articles/4402697202323-Resurface-privacy-manager)
        const waitForPrivacyPreference = setInterval(() => {
            tries++;
            const privacyPreference = document.querySelector('.privacy-preferences-trigger')
            if (privacyPreference) {
                privacyPreference.addEventListener('click', e => {
                    e.preventDefault();
                    if (window._sp_?.usnat) {
                        window._sp_.usnat.loadPrivacyManagerModal(messageId);
                    } else {
                        console.warn('Sourcepoint usnat not found.')
                    }   
                })
                clearInterval(waitForPrivacyPreference)
            }
            if (tries > 1000) {
                clearInterval(waitForPrivacyPreference)
                console.warn('Privacy preference button not found.')
            }
        }, 100)

        return () => {
            const privacyPreference = document.querySelector('.privacy-preferences-trigger')
            if (privacyPreference) {
                privacyPreference.removeEventListener('click', e => {
                    e.preventDefault();
                    if (window._sp_?.usnat) {
                        window._sp_.usnat.loadPrivacyManagerModal(messageId);
                    } else {
                        console.warn('Sourcepoint usnat not found.')
                    }
                })
            }
            clearInterval(waitForPrivacyPreference)
        }
    }, [messageId]);

    return null;
};

export default useSourcePointPrivacyTrigger;